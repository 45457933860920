.sponsor p {
  color: #64607d;
  font-size: 0.9em;
  line-height: 30px;
}
.img01,
.psTx,
.img02,
.txsp {
  width: 50%;
}
.img01 img,
.img02 img {
  width: 320px;
  object-fit: contain;
}
.img02 {
  justify-content: flex-start;
}
.img01 {
  justify-content: flex-end;
}
.dmk {
  width: 48%;
}
.dmk p {
  margin-top: 20px;
}
.gif_cnt,
.abml {
  width: 47%;
}
.gif_cn {
  width: 100%;
  height: 300px;
  border: 2px dashed #706f6f;
}
.gif_cn {
  position: relative;
}
.gif_cn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
.gif_cn video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 760px) {
  .dmk,
  .psTx,
  .img01,
  .img02,
  .txsp,
  .abml,
  .gif_cnt {
    width: 100%;
  }
  .gif_cnt {
    margin: 20px 0;
  }
  .img01 img {
    width: 100vw;
    margin-right: -5%;
    margin-top: 15px;
  }
  .jkla {
    flex-direction: column-reverse;
  }
  .img02 img {
    width: 100vw;
    margin-left: -5%;
    margin-top: 15px;
  }
  .hbtns {
    width: 100%;
    padding-bottom: 20px;
  }
  .hbtns a:first-child {
    width: 50%;
  }
  .hbtns a div {
    width: 100% !important;
  }
}
