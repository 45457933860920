.idl p {
  font-size: 0.9em;
  font-weight: 500;
  color: #64607d;
  margin-bottom: 10px;
  line-height: 25px;
}
.imgIdl {
  width: 50%;
}
.idl {
  width: 100%;
}
.tid {
  width: 50%;
}
.imgIdl img {
  object-fit: contain;
  width: 60%;
}
.tid img {
  width: 50%;
  object-fit: contain;
}

@media (max-width: 760px) {
  .imgIdl,
  .tid {
    width: 100%;
  }
  .tid {
    margin-top: 20px;
  }
}
