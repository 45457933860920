.sub {
  font-size: 0.9em;
  line-height: 25px;
}
.tx span {
  font-size: 0.8em;
  font-weight: 600;
  color: var(--yellow);
  margin-bottom: 8px;
  margin-left: 10px;
}
.img {
  position: relative;
}
.spp {
  text-align: right;
  display: block;
  color: #f17216;
  font-weight: 500;
}
.reviews {
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 10px 24px 40px rgba(168, 22, 75, 0.05);
  backdrop-filter: blur(100px);
  position: absolute;
  z-index: 10;
  bottom: 10px;
  right: 30px;
  padding: 10px 20px;
  border: none !important;
  border-width: 0px !important;
}
.reviews h2 {
  margin-right: 10px;
  font-size: 1.5em;
}
.reviews .img_rev,
.img_rev img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  object-fit: contain;
}
.img_rev img {
  cursor: pointer;
  /* display: none; */
}
.img img {
  width: 400px;
  object-fit: contain;
}
.txts {
  flex: 1;
}
.htitle {
  font-size: 3em;
  /* line-height: 70px; */
  color: var(--txt);
}

.htitle span {
  position: relative;
}
.htitle span svg {
  position: absolute;
  left: 0;
  bottom: -10px;
  width: 100%;
}
.hero p.ubuntu {
  font-size: 0.9em;
  margin-top: 20px;
  line-height: 25px;
  color: #747582;
  font-weight: 500;
}
.hbtns {
  margin-top: 20px;
}
.hbtns div {
  font-weight: 600;
  padding: 15px;
  border-radius: 30px;
  font-size: 0.9em;
  cursor: pointer;
  margin-right: 10px;
}
.hbtns div:first-child {
  background-color: var(--red);
  color: white;
  box-shadow: 0px 30px 50px rgba(168, 22, 75, 0.15);
}
.hbtns div svg {
  margin-right: 10px;
}
.jm {
  font-weight: 500;
  padding: 2% 0;
  color: #4d5959;
  font-size: 0.9em;
  line-height: 25px;
}
@media (min-width: 1000px) {
  .htitle {
    line-height: 70px;
  }
  .sub {
    max-width: 600px;
  }
  .img img {
    width: 500px;
    object-fit: contain;
  }
  .jm {
    padding: 0% 0px;
    padding-bottom: 1%;
  }
}
.hbtns.mobile {
  width: 100%;
}
.hbtns.mobile div:first-child {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 760px) {
  .jm {
    text-align: center;
    font-size: 0.8em;
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .htitle {
    font-size: 2.6em;
  }
  .hero {
    padding-top: 3%;
  }
}
.hero {
  width: 100vw;
  overflow-x: hidden;
}

.t4:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  border-left: 3px solid rgba(0, 0, 0, 0.75);
  background-color: #f7f7f7;
  width: 100%;
  height: 100%;
  animation: typewriter 3s steps(20) infinite,
    blinkTextCursor 600ms steps(1) infinite;
}

@keyframes typewriter {
  40%,
  60% {
    left: calc(100% + 4px);
  }
  100% {
    left: 0%;
  }
}
@keyframes blinkTextCursor {
  0% {
    border-left-color: rgba(0, 0, 0, 0.75);
  }
  50% {
    border-left-color: transparent;
  }
  0% {
    border-left-color: rgba(0, 0, 0, 0.75);
  }
}
