.routes_dash {
  background-color: #f7f7f7;
  flex: 1;
  height: calc(100vh) !important;
  height: 100%;
  /* margin-top: 80px; */
}
.dhpa .c404 {
  flex: 1;
  width: 100%;
}
.btn_loader div {
  border-width: 2px;
  width: 20px;
  height: 20px;
}
.gradient {
  background: linear-gradient(91.99deg, #f64c3b 0%, #b80cb6 105.61%);
}
.droutes {
  flex: 1;
  height: calc(100vh - 80px);
  width: calc(100vw - 520px);
  overflow-y: auto;
  overflow-x: hidden;
}
.internaggform {
  margin-top: 10%;
}
.duty_cnt {
  align-items: center;
}
.duty_cnt .btn {
  height: 40px;
  margin-top: 15px;
  margin-left: 10px;
}
.duty_cnt .input {
  flex: 1;
}
.chart_cnt {
  background-color: white;
}

.cnpa {
  padding: 20px;
}
.cnpa h2 {
  color: #333333;
  font-weight: 500;
  font-size: 1em;
  margin-bottom: 20px;
}
.profileImage img {
  border-radius: 50%;
  object-fit: cover;
}
.cnpa .profileImage {
  width: 100px;
  height: 100px;
}
.cnpa .profileImage img {
  width: 60px;
}
.inops {
  margin: 15px 0px;
  padding: 10px;
}
.inops span {
  font-size: 0.9em;
  display: block;
  width: 50%;
  padding: 10px;
}
.inops div {
  width: 100%;
  display: flex;
}

.inops span:first-child {
  color: #797979;
}
.inops span:last-child {
  color: #000000;
  font-weight: 600;
}
.limkn {
  display: block;
  margin-left: auto;
}
@media (max-width: 760px) {
  .inops div {
    flex-direction: column;
  }
  .inops div span {
    width: 100%;
  }
}
@media (min-width: 1100px) {
  /* .inops {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
   .inops div span {
    width: fit-content;
  } */
}
.modal_pop {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cnt_l {
  width: 80%;
  height: fit-content;
  background-color: white;
  position: relative;
}
.cnt_l span {
  font-size: 0.8em;
  font-weight: 400;
  color: #333333;
  display: block;
}
.opph {
  color: #f64c3b !important;
  display: block;
  margin-bottom: 10px;
  font-size: 0.9em;
}

.report {
  min-width: 800px !important;
  background-color: white;
  padding: 20px;
}
.report h2 {
  text-align: center;
  /* font-size: 1em; */
  color: #333333;
  font-weight: 800;
  margin-bottom: 30px;
}
.report_cnt {
  width: calc(100vw - 520px - 5vw) !important;
  /* flex: 1;
  overflow-x: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; */
  margin-top: 50px;
}

@media (max-width: 760px) {
  .report_cnt,
  .droutes {
    width: calc(100vw - 80px - 5vw) !important;
  }
}
.box {
  border: 1px solid #c4c4c4;
  /* padding: 20px; */
  border-top-width: 0px;
}
.box.first {
  border-top-width: 1px;
}
.box h3 {
  font-size: 1em;
  font-weight: 800;
  color: #f64c3b;
}
.box div {
  padding: 20px;
}
.box span {
  font-size: 0.8em;
  color: #333333;
  display: block;
  margin-top: 6px;
}
.box .dates {
  width: 200px;
  border-right: 1px solid #c4c4c4;
}
.rpap {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rpap span {
  font-size: 1em;
  color: #38cb89;
  font-weight: 600;
}
.rpap svg {
  margin-top: 10px;
  margin-left: 10px;
}
.close_b {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  /* position: absolute; */
  right: 0;
  top: 0;
  cursor: pointer;
  /* background-color: red; */
  /* margin-right: -50px;
  margin-top: -20px; */
}
.b2bs {
  width: 100%;
}

.b2bs .btn {
  width: 100%;
}

@media (min-width: 760px) {
  .b2bs {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }
  .b2bs .btn {
    width: fit-content;
    height: fit-content;
  }
}
.bgn_download_agg {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
}
.signed {
  padding: 70px 0px;
}
