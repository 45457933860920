footer {
  color: #878595;
}
.logo-wrapper {
  flex: 0.8;
}
.logo-wrapper .logo {
  width: 150px;
  height: 40px;
  margin-bottom: 10px;
}
.flogo {
  width: 100px;
}
.copy {
  text-align: center;
  font-size: var(--font-size);
  font-weight: 600;
  font-size: 0.9em;
  border-top: 2px solid #e5e5ea;
  padding-top: 15px;
}
.note {
  font-size: 0.9em;
  text-align: center;
  margin-bottom: 15px;
}
.fcnt {
  flex: 1;
  justify-content: center;
  align-items: center;
}
.fcnt p {
  font-size: 0.9em;
  padding-right: 10px;
  line-height: 25px;
}
.fcnt ul {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.fcnt ul span {
  margin: 0 10px;
}
footer ul {
  list-style: none;
  font-size: 0.9em;
}
.fcnt .sbtitle {
  font-size: 1em;
  color: #242426;
}
.news-letter {
  flex: 0.8;
}
.link {
  display: flex;
  align-items: center;
}
footer ul li {
  color: #878595;
  line-height: 1.8rem;
}
footer ul li:hover {
  font-weight: 500;
}
footer .mfc {
  margin-bottom: 30px;
}
.input_news {
  width: 100%;
  height: 40px;
  background-color: #f0f0f0;
  margin-top: 10px;
}
.input_news .input {
  flex: 1;
  border: none;
  outline: none;
  padding: 10px;
  background: none;
}
.input_news .submit {
  background-color: #8896a6;
  width: 30%;
  color: white;
  font-weight: 600;
  outline: none;
  border: none;
  cursor: pointer;
  border-radius: 0;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0; /* Safari 3-4, iOS 1-3.2, Android 1.6- */
  -moz-border-radius: 0; /* Firefox 1-3.6 */
  border-radius: 0; /* Opera 10.5, IE 9, Safari 5, Chrome, Firefox 4, iOS 4, Android 2.1+ */
}
.lcn {
  margin-top: 20px;
}
.lcn p {
  font-size: 0.8em;
}
.lcn svg {
  width: 80px;
  margin-right: 10px;
}
.socials {
  margin-top: 15px;
}
.socials a {
  color: black;
  padding-right: 20px;
  cursor: pointer;
}
@media (max-width: 760px) {
  .mb {
    margin: 20px 0;
  }
}
