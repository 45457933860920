.what_cnt {
  padding-top: 2%;
  padding-bottom: 2%;
}
.pm {
  flex: 1;
  max-width: 48%;
}
.bg_img {
  width: 100%;
  min-height: 250px;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: center bottom;
}
.pm p {
  color: #64607d;
  font-size: 0.9em;
  margin: 20px 0;
  padding-right: 20px;
  line-height: 25px;
}
.pm p ol {
  padding-left: 15px;
}
.pm ul li {
  margin-bottom: 10px;
}
.ulw {
  list-style: none;
  /* padding-left: 3%; */
}

.ulw li {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  color: #64607d;
  line-height: 25px;
  margin-bottom: 10px;
}

.ulw li svg {
  width: 20px;
  margin-top: 5px;
  margin-right: 10px;
}
.vr_cnt {
  width: 190px;
  height: 180px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  background-repeat: no-repeat;
  background-size: cover;
}
.iframe {
  width: 100%;
  height: 315px;
  margin-top: 50px;
}
.iframe iframe {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 760px) {
  .what_cnt {
    width: 100%;
  }
  .pm {
    width: 100%;
    max-width: 100%;
  }
  .bg_img {
    width: 100vw;
    margin-left: -5%;
    background-attachment: initial;
  }
  .iframe {
    /* margin-top: 0; */
    width: 100vw;
    margin-left: -5%;
  }
}
