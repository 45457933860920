.goals {
  padding-top: 2%;
  padding-bottom: 2%;
}
.gd {
  flex: 1;
  margin-top: 20px;
  position: relative;
}
.svg_m {
  width: 80px;
  height: 80px;
  background-color: #fff2f2;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
}
.gd p {
  text-align: center;
  margin-left: auto;
  margin-top: 10px;
  font-size: 0.8em;
  font-weight: 600;
  color: #37474f;
}
.svg_m .img {
  width: 70%;
  height: 70%;
  background-size: contain;
}
.arr {
  position: absolute;
  top: 20%;
  right: -20%;
  width: 40%;
}
.arr svg {
  width: 100%;
}

@media (max-width: 760px) {
  .glm {
    flex-wrap: wrap;
  }
  .gd {
    flex: unset;
    width: 50%;
  }
  .goals {
    padding-bottom: 40px;
  }
}
