.input {
  position: relative;
}
.ptoggle {
  width: 40px;
  height: 40px;
  /* background-color: red; */
  position: absolute;
  top: 33px;
  right: 0;
  cursor: pointer;
}
.input label {
  color: #4d5959;
  font-weight: 600;
  font-size: 0.9em;
  cursor: pointer;
  display: block;
  margin-bottom: 10px;
}
.in_c {
  width: 100%;
}
.input input,
.input textarea,
.picker,
.select {
  width: 100%;
  height: 50px;
  border: 1px solid #55585c2b;
  outline: none;
  color: #838383;
  font-weight: 500;
  padding: 10px;
  background-color: white;
}
.input input:focus {
  border-width: 2px;
}
.input {
  margin-bottom: 10px;
}
.input textarea {
  height: 120px;
}
.select {
  cursor: pointer;
  position: relative;
}
.select span {
  font-size: 0.8em;
  color: #838383;
  font-weight: 500;
}
.options {
  width: 100%;
  padding: 20px;
  background-color: white;
  box-shadow: 0px 24px 24px rgba(55, 52, 169, 0.3);
  position: absolute;
  top: 50px;
  left: 0;
  list-style: none;
  max-height: 300px;
  overflow-y: auto;
  z-index: 10;
}

.options li {
  width: 100%;
  padding: 10px;
  font-size: 0.9em;
  font-weight: 600;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
@media (min-width: 760px) {
  .inputs {
    display: flex;
    justify-content: space-between;
  }
  .inputs .input {
    width: 48%;
  }
}
