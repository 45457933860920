@import url("https://fonts.googleapis.com/css2?family=Mansalva&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Red+Rose:wght@300&display=swap");
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.mansalva {
  font-family: "Mansalva", cursive;
}
.poppins {
  font-family: "Poppins", sans-serif;
}
.manrope {
  font-family: "Manrope", sans-serif;
}
.ubuntu {
  font-family: "Ubuntu", sans-serif;
}
.rr {
  font-family: "Red Rose", cursive;
}
/* .app_cnt {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
} */
/* .routes {
  width: 100vw;
  height: calc(100vh - 60px);
  overflow: hidden;
  overflow-y: auto;
} */
a {
  text-decoration: none;
}
*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: inherit;
}
:root {
  --bg: #f7f7f7;
  --txt: #282c4b;
  --yellow: #f17216;
  --red: #e33861;
}
.containerh {
  padding-top: 2%;
  padding-bottom: 2%;
}
.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}

.container {
  padding-left: 5%;
  padding-right: 5%;
}
.bg {
  background-color: var(--bg);
}
.logos_col {
  margin-top: 50px;
  margin-bottom: 50px;
}
.logos_col img {
  /* height: 40px; */
  width: 400px;
  height: auto;
  margin-right: 20px;
}

@media (max-width: 760px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  .mobile.flex {
    display: flex;
  }
  .mfc {
    flex-direction: column;
  }
}
.btn {
  background-color: #ff5043;
  width: fit-content;
  color: white;
  padding: 10px 40px;
  cursor: pointer;
  font-size: 0.9em;
  font-weight: 600;
}
@media (min-width: 760px) {
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
  .desktop.flex {
    display: flex;
  }
}
.c404 {
  width: 100vw;
  height: calc(100vh - 60px);
  font-size: 3em;
  color: var(--txt);
  opacity: 0.6;
}
.lp .c404 {
  width: calc(100vw - 10%);
}

.btn .loader div {
  border-width: 2px;
  width: 20px;
  height: 20px;
}
.error-text {
  color: var(--red);
  font-weight: 600;
  font-size: 0.8em;
}
.ubtn_upload {
  border-width: 0px;
  margin-left: 10px;
  width: fit-content;
  padding: 10px 20px;
  height: fit-content;
}
.btn_bbn {
  border-width: 0px;
}
.marquee .blur {
  filter: blur(3.5px);
  opacity: 0.5;
}
