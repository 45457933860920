.udan {
  display: flex;
  justify-content: center;
  align-items: center;
}
.kah {
  margin-bottom: 40px;
}
.nt img {
  width: 300px;
  object-fit: contain;
}
.nt {
  flex: 1;
}
.nt p,
.kadh {
  font-size: 0.9em;
  line-height: 25px;
  color: #64607d;
  font-weight: 500;
}
@media (min-width: 760px) {
  .nt {
    max-width: 50%;
  }
}
@media (max-width: 760px) {
  .kah {
    flex-direction: column-reverse;
  }
  .ite .iframe {
    width: 100vw;
    margin-left: -5vw;
  }
  .adhj {
    padding-top: 80px;
    margin-bottom: 40px;
  }
  .udan img {
    margin-left: -0% !important;
  }
  .nt img {
    width: 100vw;
    margin-left: -5vw;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
