.acct_inf {
  width: 300px !important;
}

.profileImage {
  background-color: #ffecec;
  border-radius: 50%;
  position: relative;
  width: 130px;
  height: 130px;
  margin-left: auto;
  margin-right: auto;
  z-index: 2;
}
.profileImage img {
  width: 80%;
  aspect-ratio: 1/1;
}
.profileImage div {
  background-color: #f8dcdc;
  width: inherit;
  height: inherit;
  transform: scale(0.8);
  border-radius: inherit;
}
.name {
  text-align: center;
  font-size: 1.5em;
  margin: 10px 0;
}
.bgn_download {
  padding: 10px;
  color: white;
  border-radius: 10px;
  margin: 10px 0;
  cursor: pointer;
}
.bgn_download .svg_d {
  margin-right: 10px;
}
.hlo {
  font-size: 0.9em;
  text-align: center;
  color: #8f8f8f;
  display: block;
}
.act_link {
  width: 40px;
  height: 40px;
  position: relative;
  margin-left: 10px;
}

.act_link img {
  width: 30px;
  border-radius: 50%;
  aspect-ratio: 1/1;
}
.links {
  margin-bottom: 20px;
  justify-content: flex-end;
}
.badge {
  position: absolute;
  font-size: 0.6em;
  background-color: #ff5630;
  width: 15px;
  height: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 500;
  border-radius: 50%;
  top: 0;
  right: 0;
}
.alerts {
  overflow-y: auto;
  border-bottom: 1px solid #0000000f;
}
.disabled {
  background-color: #b2b2b233;
  background: #b2b2b233;
}
.disabled span {
  color: #4d5959;
  font-weight: 600;
}

@media (max-width: 760px) {
  .acct_inf {
    display: none !important;
  }
}
