header {
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100vw;
  height: 60px;
  position: fixed;
  top: 0;
  z-index: 200111111111111111111;
  overflow: hidden !important;
}
/* .htopw {
  position: absolute;
}
.htopw svg {
  transform: scale(20) rotate(-122.61deg) translateY(22px) translateX(16px);
} */
header ul {
  list-style: none;
  margin-bottom: 10px;
}
header ul li {
  background: none !important;
}
header ul li a {
  padding: 10px;
  font-weight: 400;
  color: #4d5959;
  font-weight: 600;
}
header ul li.active a,
header ul li:hover a {
  color: var(--red);
  font-weight: 700;
}
.sht {
  position: absolute;
  /* width: 100%; */
  transform: rotate(-90deg);
  /* background-color: green; */
  left: 22%;
  z-index: 1;
  margin-bottom: 50px;
  /* background-color: green; */
}

@media (max-width: 760px) {
  .llap {
    margin-top: 5px;
    transform: scale(0.8);
    margin-left: -20 px;
    /* background-color: red; */
  }
  .menu {
    width: 30px;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .menu div {
    width: 100%;
    height: 5px;
    background-color: #4d5959;
    margin-bottom: 3px;
  }
  .nav {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: -100vh;
    left: 0;
    background-color: white;
    z-index: 1000000000000;
    transition: 0.5s all ease-in-out;
  }
  .nav.opened {
    top: 60px;
  }
  .nav ul {
    flex-direction: column;
  }
  .nav ul li {
    padding: 0px;
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .llap {
    margin-left: -20px;
  }
}
.nav ul li a {
  width: 100%;
  display: block;
  padding: 20px;
}
.nav {
  overflow-y: auto;
}

@media (max-width: 1600px) {
  .sht {
    display: none;
  }
}

/* @media (max-width: 1000px) and (min-width: 760px) {
  .maj li {
    font-size: 0.8em;
  }
} */
@media (min-width: 760px) and (max-width: 1540px) {
  .maj li {
    font-size: 0.7em !important;
  }
}
