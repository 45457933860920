.certificate_container {
  /* margin: 50px; */
  width: 1650px;
  height: 1275px;
  /* width: 1650px;
  height: 1275px; */
  background-color: #990a8a;
  position: relative;
  /* transform: scale(0.1); */
  /* background: linear-gradient(180deg, #f64c3b 4.49%, #b80cb6 100%); */

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.certificate_container h2 {
  text-align: center;
  font-size: 64px;
  color: white;
}
.cert_cnt {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  flex-direction: column;
}
.cert_cnt h4 {
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
  margin-top: 200px;
}
.cert_cnt h1 {
  font-size: 96px;
  margin-bottom: 63px;
  font-weight: 700;
  text-transform: uppercase;
}
.cert_cnt p {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  text-align: center;
  width: 45%;
  margin-bottom: 50px;
}
.cert_cnt h5 {
  text-transform: uppercase;
  font-size: 20px;
  line-height: 30px;
  position: absolute;
  bottom: 60px;
}
.cnt_crt,
.logo_cert {
  padding: 100px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  display: flex;
  flex-direction: column;
}
.pplh {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  margin-top: 30px;
  margin-bottom: 60px;
}
.logo_cert {
  padding: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logo_cert img {
  width: 60%;
  opacity: 0.8;
  margin-top: 100px;
}
.cert_cnt {
  flex: 1;
}
.scfooter {
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
}
.scfooter img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
