.picker {
  min-height: 130px;
  background-color: white;
  cursor: pointer;
  border-style: dashed;
  flex-direction: column;
}
.picker span {
  display: block;
  text-align: center;
  font-size: 0.8em;
  margin-bottom: 10px;
}
.picker svg {
  width: 20px;
  margin-left: auto;
  margin-right: auto;
}
input[type="file"] {
  display: none;
}
.preview {
  width: 30%;
  height: 100%;
}
.preview img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
