.alert {
  border: 1px solid black;
  padding: 5px;
  font-size: 0.9em;
  font-weight: 600;
  color: #333333;
  border-radius: 10px;
  margin: 15px 0px;
}
.alert .svg_in {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
.alert.success {
  background-color: #38cb8930;
  border-color: #38cb8980;
}
.alert.success .svg_in {
  background-color: #38cb89;
}

.alert.info {
  background-color: #036ee429;
  border-color: #036ee480;
}
.alert.info .svg_in {
  background-color: #036ee4;
}

.alert.warn {
  background-color: #ee950029;
  border-color: #ee950080;
}
.alert.warn .svg_in {
  background-color: #ee9500;
}

.alert.error {
  background-color: #cf523829;
  border-color: #cf523880;
}
.alert.error .svg_in {
  background-color: #cf5238;
}

.alert p {
  flex: 1;
}
.alert p {
  display: block;
  flex: 1;
  width: calc(100% - 100px);
  font-size: 0.9em;
}
