.gli {
  width: 50%;
  margin-bottom: 20px;
  padding-right: 20px;
}
.g_ob {
  width: 100px;
  height: 80px;
  background-color: white;
}
.objs {
  width: 100%;
  flex-wrap: wrap;
  margin-top: 50px;
}
.gli p {
  padding-left: 30px;
  margin-left: 30px;
  position: relative;
  flex: 1;
  font-size: 0.9em;
  line-height: 25px;
}
.gli p::after {
  content: "";
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #f64c3b;
}
.img_ob {
  width: 60%;
  height: 60%;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 20%;
}
.gli:last-child .img_ob {
  margin-left: 0%;
}
@media (max-width: 760px) {
  .gli {
    width: 100%;
    margin-bottom: 30px;
  }
  .gli p {
    font-size: 0.8em;
  }
}
