.supplier-nda {
  margin: 60px 0;
  padding: 40px 10vw;
  background-color: var(--bg);
}

.supplier-nda h1 {
  margin-bottom: 40px;
}

.supplier-nda p {
  font-size: 0.85rem;
  text-align: center;
  font-weight: 500;
  font-family: "Manrope";
}

.supplier-nda .form-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.supplier-nda button {
  margin-top: 40px;
}

@media (max-width: 760px) {
  .supplier-nda {
    padding: 40px 5vw;
  }

  .supplier-nda .form-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
