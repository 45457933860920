.table {
  width: 100%;
  overflow-x: auto;
  /* flex: 1; */
  width: calc(100vw - 520px - 5vw);
}
.row {
  padding: 0px 10px;
  width: 100%;
  overflow-x: auto;
  background-color: white;
  border-bottom: 2px solid #1e202514;
}
.row div {
  flex: 1;
  /* display: flex;
  justify-content: center;
  align-items: center; */
  font-size: 0.9em;
  white-space: nowrap;
  padding: 20px;
}
.row.head div {
  font-weight: 600;
  color: #333333;
}
.row.head {
  margin-top: 40px;
}
.tday {
  width: 20%;
  /* max-width: 130px; */
  min-width: 150px;
}
.row .dnd {
  /* min-width: 50%; */
}
@media (max-width: 760px) {
  .table {
    width: calc(100vw - 0px);
    overflow-x: auto;
  }
  .row {
    padding: 15px 10px;
    width: 100%;
    background-color: white;
  }
}

@media (max-width: 760px) {
  .table {
    width: calc(100vw - 110px);
  }
}
