.paint {
  width: 400px;
  height: 400px;
  /* background-color: red; */
  position: absolute;
  z-index: -1;
  bottom: 100px;

  /* background-attachment: fixed; */
  background-repeat: no-repeat;
  background-size: contain;
}
.services {
  position: relative;
  margin-bottom: 100px;
}
.svnt,
.imgGvt {
  flex: 1;
}
.imgGvt img {
  width: 300px;
  object-fit: contain;
}
.cnt_bg {
  width: 100vw;
  height: calc(100% - 300px);
  position: absolute;
  z-index: -1;
  top: 200px;
  left: 0;
}
.svnt {
  position: relative;
}
.svnt span {
  color: #ff5043;
  font-weight: 700;
  font-size: 0.9em;
}
.svnt h2 {
  font-size: 2em;
  color: #161616;
  margin-bottom: 10px;
}
.svnt p,
.svnt ul {
  font-size: 0.9em;
  color: 161616;
  list-style: none;
  position: relative;
}
.svnt ul {
  margin-top: 20px;
  padding-left: 30px;
}
.svnt ul li {
  margin-bottom: 8px;
}
.svnt ul::after {
  content: "";
  width: 5px;
  height: 100%;
  background-color: #ff5043;
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 0;
}
.chvs {
  width: 40px;
  height: 40px;
  background-color: #f24744;
  border-radius: 50%;
  position: absolute;
  top: calc(50%);
  cursor: pointer;
  z-index: 2;
}
.chvs.left {
  left: -70px;
}
.chvs.right {
  right: 0;
}
@media (max-width: 760px) {
  .imgGvt,
  .svnt {
    width: 100%;
  }
  .svnt {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .svnt span {
    margin-right: auto;
    margin-left: 18%;
  }
  .imgGvt img {
    width: 180px;
  }
  .cnt_bg {
    height: calc(100% - 120px);
    top: 120px;
  }
  .svnt p,
  .svnt ul {
    font-size: 0.7em;
  }
  .chvs.left {
    left: 0;
  }
}
.overlap {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 100px;
  left: 0;
  z-index: 10;
  padding-top: 2%;
  padding-bottom: 2%;
  font-size: 0.9em;
}
.chkl {
  margin-top: 20px;
}
.chkl input {
  margin-right: 10px;
}
.chkl button {
  background-color: #f64c3b;
  color: white;
  padding: 10px;
  width: fit-content;
  margin-left: 20px;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 10px;
  font-size: 0.9em;
  font-weight: 500;
}
.overlap p,
.tnxs p {
  line-height: 25px;
  font-size: 0.9em;
}
@media (max-width: 760px) {
  .chkl {
    align-items: flex-start;
  }
  .chkl button {
    margin-left: 0;
    margin-top: 20px;
  }
  .services {
    min-height: 530px !important;
  }
  .chvs {
    transform: scale(0.75) !important;
    top: -50px;
  }
  .chvs.right {
    transform: rotate(180deg) scale(0.75) !important;
  }
  .svnt ul li {
    font-size: 1.2em;
  }
}
.popup_invt {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  z-index: 300111111111111111111;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.splc {
  background-color: white;
  padding: 10px;
  display: flex;
  flex-direction: column;
  max-width: 300px;
  width: 80%;
}
.splc .close_pop {
  padding: 10px;
  color: #ff5043;
  font-weight: 600;
  text-align: right;
  cursor: pointer;
}
.popup_title {
  color: #ff5043;
  font-weight: 600;
}
.splc a {
  background-color: #f7f7f7;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  align-items: center;
  margin: 10px 10px;
  cursor: pointer;
}
.splc a span {
  font-weight: 700;
  display: block;
  color: #161616;
}
.arr_paj {
  width: 30px;
  height: 30px;
  background: #f24744;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}
.splc p {
  padding: 10px;
  font-size: 0.9em;
}
.splc {
  border-radius: 15px;
}
@media (max-width: 760px) {
  .close_pop p {
    margin-bottom: 10px;
    font-size: 0.9em;
    display: none;
  }
  .splc {
    margin: 5%;
    width: 80%;
    max-width: 400px;
  }
}
@media (max-width: 760px) {
  .overlap {
    top: 80px;
    height: fit-content;
    padding-bottom: 20px;
  }
  .about {
    margin-top: 50px;
  }
}
