.loader div {
  width: 50px;
  height: 50px;
  border: 10px solid var(--txt);
  border-radius: 50%;
  animation: rotation 2s infinite linear;
  border-top-color: var(--red);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.appLoader {
  min-height: 100vh;
}
