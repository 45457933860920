.container_auth {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#resetForm {
  width: 90%;
  min-height: 50%;
  background: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  padding: 40px;
  padding-top: 20px;
}
#resetForm .logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}
#resetForm h2 {
  text-align: center;
  color: #333333;
  font-size: 1.3em;
}
#resetForm input {
  width: 100%;
  background-color: #e3e3e32e;
  border: 1px solid #e3e3e3;
  margin-bottom: 20px;
  padding: 10px;
}
#resetForm label {
  color: #333333;
  font-weight: 400;
  font-size: 0.8em;
  margin-bottom: 5px;
  display: block;
  cursor: pointer;
}
#resetForm #submit {
  background: linear-gradient(95.32deg, #f64c3b 5.13%, #b80cb6 114.76%);
  border-radius: 4px;
  color: white;
  cursor: pointer;
  font-weight: 600;
}
.message {
  margin-bottom: 20px;
  font-size: 0.8em;
  color: red;
}
.link_sr {
  font-size: 0.9em;
  color: #333333;
  font-weight: 500;
  text-align: center;
  display: block;
  color: #8f8f8f;
}
.container_auth span {
  text-align: center;
  color: #8f8f8f;
  font-size: 0.8em;
  font-weight: 500;
  display: block;
  margin-bottom: 30px;
}
@media (min-width: 760px) {
  #resetForm {
    max-width: 500px;
    max-height: 500px;
  }
}
.link_sr.kko {
  text-align: right !important;
}
