.cards {
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.card {
  background-color: #fff;
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  padding: 20px;
  position: relative;
  width: fit-content;
  margin-bottom: 20px;
  cursor: pointer;
}
.card::after {
  content: "";
  width: 4px;
  height: 70%;
  position: absolute;
  left: 0;
  top: 15%;
}
.card:nth-child(1):after {
  background-color: #fcb833;
}
.card:nth-child(2):after {
  background-color: #2672a3;
}
.card:nth-child(3):after {
  background-color: #fd762d;
}
.card:nth-child(4):after {
  background-color: #9dcc26;
}
.card:nth-child(5):after {
  background-color: #d4ca2a;
}
.card img {
  width: 40px;
  object-fit: contain;
}
.card .cnt_ {
  margin-left: 15px;
  margin-top: -10px;
}
.card p {
  font-size: 0.7em;
  font-weight: 400;
  max-width: 300px;
  margin-top: 10px;
}
.tt {
  text-align: center;
  font-weight: 400;
  font-size: 1.5em;
  color: #898989;
  margin-top: 20px !important;
}
.card h2 {
  font-size: 1.2em;
  color: #14183e;
}
.phones_ a {
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;

  background-color: #f7f7f7;
  border-radius: 50%;
  color: var(--primary);
  font-size: 0.8em;
  cursor: pointer;
}
.phones_ a:hover {
  color: var(--orange);
}
.phones_ {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 10px;
}

@media (max-width: 760px) {
  .stitle {
    font-size: 2.4em;
  }
}
