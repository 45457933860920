.dheader {
  width: 100%;
  height: 80px;
}

.dheader p {
  color: #333333;
  font-size: 0.9em;
  font-weight: 500;
}
