.sh_txh {
  flex: 1;
  justify-content: center;
  align-items: center;
}
.sh_txh h2 {
  font-size: 2em;
  font-weight: 700;
}
.sh_txh p {
  font-size: 0.8em;
  color: #64607d;
}
.inps {
  flex: 1;
}
.inps textarea {
  padding: 20px;
  width: 400px;
  border: 2px solid #f1f1f1;
  resize: none;
  outline: none;
  margin-bottom: 10px;
}
.sss {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 400px;
}
.sss a {
  width: 35px;
  height: 35px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eff0f2;
  border-radius: 50%;
  text-decoration: none;
  color: var(--primary);
}
.sss a:hover {
  color: var(--orange);
}

@media (max-width: 760px) {
  .sh_txh h2 {
    font-size: 1em;
    text-align: center;
  }
  .sh_txh p {
    text-align: center;
    margin-bottom: 10px;
    font-size: 0.7em;
  }
  .sss,
  .inps textarea {
    width: 100%;
  }
}
