.list {
  padding-bottom: 2%;
  padding-top: 2%;
}
.fxx {
  flex-direction: column;
}
.fxx span {
  display: block;
}
.fxx div {
  width: 60px;
  height: 60px;
  font-weight: 800;
  color: var(--red);
  border-radius: 20px;
}
.fxx span:last-child {
  font-size: 0.8em;
  color: #797979;
  font-weight: 500;
}
.fxx svg {
  width: 25px;
}
@media (max-width: 760px) {
  .fxx {
    margin-bottom: 20px;
  }
  .fxx div {
    width: 80px;
    height: 80px;
    font-size: 1.4em;
    margin-bottom: 10px;
  }
  .fxx span {
    font-size: 1.2em;
  }
}
