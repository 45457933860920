.lcontent h2 {
  background-color: var(--red);
  font-size: 1.2em !important;
  padding: 5px;
  width: fit-content;
  margin: 15px 0;
  color: white;
}
.lp p {
  font-size: 0.9em;
  color: #64607d;
  font-weight: 500;
  line-height: 30px;
}
.lcontent {
  font-size: 0.9em;
}
.lcontent ol, .lcontent ul {
  font-size: 0.9em;
  color: #64607d;
  font-weight: 500;
  line-height: 30px;
  margin-left: 15px;
}

.lcontent strong {
  font-weight: 900 !important;
}
.lcontent table {
  margin-top: 10px;
  margin-bottom: 10px;
}

.lcontent td {
  padding: 10px;
}
.lkm {
  list-style: none;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.lkm a {
  color: white;
  padding: 10px;
  background-color: var(--red);
}
.lkm a:hover {
  font-weight: 600;
}

@media (max-width: 760px) {
  .lcontent ul {
    list-style: none;
    padding: 20px;
  }
  .lkm a {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media (max-width: 780px) {
  .lcontent table tr {
    display: flex;
    flex-wrap: wrap;
    width: calc(100vw - 7% - 60px);
  }
  .lcontent table a {
    word-wrap: break-word;
  }
}
