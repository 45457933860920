nav,
.acct_inf {
  width: 220px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 100vh;
}

nav h3.title {
  color: #000000;
  margin: 30px 0;
  margin-left: 10px;
}

nav ul li {
  padding: 10px 10px;
  width: 100%;
  margin-bottom: 10px;
}
nav ul li .svg_l {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}
nav ul li span {
  font-weight: 600;
  color: #797979;
}

nav ul li:hover,
nav ul li.active {
  background: linear-gradient(91.56deg, #f64c3b 0%, #b80cb6 105.16%);
}
nav ul li:hover span,
nav ul li.active span {
  color: white;
}
nav ul li:hover .svg_l svg path,
nav ul li.active .svg_l svg path {
  fill: white;
  stroke: white;
}
nav ul li:hover .svg_l svg.check path,
nav ul li.active .svg_l svg.check path {
  fill: none !important;
}
.sp {
  flex: 1;
  border-bottom: 1px solid #0000000f;
}
.rnm {
  margin-bottom: 20px;
}

@media (max-width: 760px) {
  nav {
    width: 80px;
    /* overflow-x: hidden; */
  }
  nav ul li {
    justify-content: flex-start;
  }
}
