.refund{
  min-height: 80vh;
  padding: 100px 0;
}

.refund h1 {
  text-align: center;
  margin-bottom: 40px;
}

.refund .formWrapper {
  padding: 0 5vw;
}

.refund .formWrapper .formSign {
  margin-left: 0;
  width: 100%;
  padding: 40px 5%;
}

.refund .formWrapper .formSign .inputs {
  margin-bottom: 20px;
}
.refund input[type="file"] {
  display: initial;
}

.receipt-input {
  width: 100%;
}

.receipt-input label {
  color: #4d5959;
  font-weight: 600;
  font-size: 0.9em;
  cursor: pointer;
  display: block;
  margin-bottom: 10px;
}

.receipt-input input {
  width: 100%;
  height: 50px;
  border: 1px solid #55585c2b;
  outline: none;
  color: #838383;
  font-weight: 500;
  padding: 10px;
  background-color: white;
}

.success {
  text-align: center;
  color: rgb(0, 199, 99);
  font-size: 0.85rem;
  font-weight: 500;  
}