.internhero {
  padding-top: 40px;
}
.txs_ih {
  flex: 1;
}
.txs_ih h2 {
  font-size: 1.8em;
  color: #282c4b;
}

.csp {
  border: 1px solid black;
  border-radius: 50%;
  padding: 20px;
  position: relative;
  color: #e33861;
}
.csp::before {
  content: "";
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  border: 1px solid black;
  border-radius: 50%;
  transform: matrix(1, -0.08, 0.05, 1, 0, 0);
}
.btns_inh {
  justify-content: space-between;
}
.btns_inh div {
  width: 48%;
  padding: 10px 20px;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.btns_inh div a {
  width: 100%;
  height: 100%;
  /* background-color: red; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #797979;
}
.btns_inh div:first-child {
  background-color: #e33861;
  color: white;
  box-shadow: 0px 30px 50px rgba(168, 22, 75, 0.15);
}
.btns_inh div:last-child {
  border: 2px solid #797979;
  color: #797979;
  font-weight: 700;
  padding: 0px;
}
.txs_ih span {
  font-weight: 400;
  color: #f17216;
  font-size: 0.8em;
}

@media (min-width: 760px) {
  .btns_inh {
    width: 300px;
  }
  .txs_ih h2 {
    font-size: 2.7em;
    line-height: 75px;
  }
  .txs_ih {
    flex: 1;
  }
  .btns_inh {
    transform: translateY(-80px);
  }
}

@media (min-width: 1100px) {
  .txs_ih {
    max-width: 50%;
  }
  .ada {
    align-items: center;
  }
}
@media (max-width: 760px) {
  .csp {
    padding: 10px;
  }
  .txs_ih h2 {
    line-height: 50px;
    font-size: 2.3em;
  }
  .picture {
    position: relative;
  }
  .picture img {
    width: 100%;
  }
  .picture .grd {
    width: 100vw;
    height: 100px;
    position: absolute;
    bottom: 0;
    background: linear-gradient(
      177.98deg,
      rgba(245, 242, 243, 0) 7.04%,
      #f7f7f7 66.46%
    );
    left: -5vw;
  }
  .btns_inh div {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
@media (max-width: 300px) {
  .txs_ih h2 {
    line-height: 50px;
    font-size: 1.8em;
  }
}
